// @ts-nocheck
import { init, getDeviceId, getSessionId, track } from '@amplitude/analytics-browser'
import { store } from '../services/store/store'
import { executePayloads } from '../shared_utils/execute'
import { apiEvent } from '../services/api/api-event'

export const DEBUG_MODE: boolean =
    process.env.DEBUG_MODE === 'true' || process.env.REACT_APP_DEBUG_MODE === 'true'

const areAnalyticsEnabled = () => {
    const configAnalytics = store.getState().config.queryParams.analytics
    return (
        window.location.hostname !== 'localhost' &&
        (configAnalytics === undefined || configAnalytics)
    )
}

const trackingDebug =
    DEBUG_MODE ||
    window.location.hostname === 'localhost' ||
    !!window.location.hostname.match(/\.veesual\.ai$/) ||
    !!window.location.hostname.match(/-veesual\.vercel\.app$/)

const hasAnalytics = () => {
    return true
}

let areAnalyticsLoaded = false
const initTracking = () => {
    // ---- Segment ----
    if (hasAnalytics()) {
        if (areAnalyticsEnabled()) {
            if (trackingDebug) {
                init('14b31c452b81ab5f02276fcba7a343bc', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            } else {
                init('6745d3ed5a0f89fce03654efe4a92e81', null, {
                    serverUrl: 'https://segment.veesual.ai/event',
                    defaultTracking: false,
                })
            }
        }
        areAnalyticsLoaded = true
    }
}

const getBasicProperties = () => {
    let origin = window.location.href
    if (origin !== null) {
        const originSplit = origin.split('?')
        if (originSplit.length == 2) {
            let originQuery = originSplit[1]
            originQuery = originQuery.replace(
                /(^|&)(utm_[^&#=]*|fbclid|gclid|gclsrc|_ga|mc_cid|mc_eid|_branch_match_id)(=[^&#]*)?/gi,
                ''
            )
            originQuery = originQuery.replace(/^&/, '')
            origin = originSplit[0] + (originQuery != '' ? '?' + originQuery : '')
        }
    }

    const config = store.getState().config.config
    const queryConfig = store.getState().config.queryParams
    const apiConfig = store.getState().config.apiConfig

    const basicProperties = {
        module: 'switch_model',
        client_id: apiConfig?.id,
        client_internal: apiConfig?.internal,
        client_name: apiConfig?.name,
        group_id: apiConfig?.group_id || null,
        group_name: apiConfig?.group_name || null,
        experience_id: apiConfig?.experience_id || null,
        experience_name: apiConfig?.experience_name || null,
        locale: queryConfig.locale || null,
        domain: queryConfig.domain || null,
        origin: origin,
        cta_location: config.cta_location || null,
        version: 1.0,
    }

    for (const field of ['garment_id', 'look_id', 'focus']) {
        if (queryConfig[field]) {
            basicProperties[`init_${field}`] = queryConfig[field]
        }
    }
    if (basicProperties.init_look_id) {
        basicProperties.init_garment_look_id = basicProperties.init_look_id.replace(/^[^&]+&/, '')
    }

    return basicProperties
}

const getPageNameAndProperties = () => {
    return { name: 'Switch Model', properties: {} }
}

const doTrackPage = async () => {
    // ---- Ajout des basics ----
    const pageAndProperties = getPageNameAndProperties()
    const finalProperties = Object.assign(getBasicProperties(), pageAndProperties.properties)

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackPage', pageAndProperties.name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsEnabled()) {
        if (areAnalyticsLoaded) {
            track(`Viewed ${pageAndProperties.name} Page`, finalProperties)
        }
    }
}

const addPrefixToProperties = (prefix, properties: any) => {
    const prefixed = {}
    for (const key in properties) {
        prefixed[prefix + key] = properties[key]
    }
    return prefixed
}

const convertProperties = (properties?: any) => {
    // ---- Traduction des properties ----
    let convertedProperties = {}
    if (properties !== undefined && properties !== null) {
        if (Array.isArray(properties)) {
            for (const one of properties) {
                convertedProperties = Object.assign(convertedProperties, convertProperties(one))
            }
        } else if (typeof properties.garment_id !== 'undefined') {
            convertedProperties = {
                item_internal_id: properties.garment_id,
                item_external_id: properties.product_external_id || null,
                item_name: properties.product_name,
                item_type: properties.garment_type,
                item_price: properties.product_price,
            }
        } else if (typeof properties.model_id !== 'undefined') {
            convertedProperties = {
                model_id: properties.model_id,
                model_name: properties.model_name,
                model_height: properties.model_height,
                model_garment_size: properties.model_garment_size,
            }
        } else if (typeof properties.look_id !== 'undefined') {
            const garmentTypes = store.getState().config.apiConfig?.garment_types || [
                'TOP',
                'BOTTOM',
                'DRESS',
                'OUTERWEAR',
            ]
            let nbItems = 0
            let totalPrice = 0
            const types = []
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    nbItems += 1
                    totalPrice += properties[typeLower].product_price
                    types.push(type)
                }
            }
            convertedProperties = Object.assign(
                {
                    outfit_id: properties.look_id,
                    outfit_nb_items: nbItems,
                    outfit_total_price: totalPrice,
                    outfit_types: types.join(','),
                },
                convertProperties(properties.model)
            )
            for (const type of garmentTypes) {
                const typeLower = type.toLowerCase()
                if (properties[typeLower]) {
                    convertedProperties = Object.assign(
                        convertedProperties,
                        addPrefixToProperties(
                            typeLower + '_',
                            convertProperties(properties[typeLower])
                        )
                    )
                }
            }
        } else if (typeof properties === 'object') {
            convertedProperties = properties
        }
    }
    return convertedProperties
}

const convertFinalProperties = (name: string, properties: Record<string, any>) => {
    // ---- Surcharge globale si besoin ----
    if (name === 'Item Added to cart') {
        return Object.assign(properties, {
            revenue: properties.item_price,
            price: properties.item_price,
            currency: 'EUR',
            // ---- Old segment fields ----
            $revenue: properties.item_price,
            $price: properties.item_price,
            $quantity: 1,
        })
    }

    return properties
}

const postInitEventWhenDeviceAndSession = (
    type: string,
    finalProperties: Record<string, any>,
    n = 0
) => {
    const deviceId = getDeviceId()
    const sessionId = getSessionId()
    if (deviceId !== undefined && sessionId !== undefined) {
        store.dispatch(
            apiEvent.endpoints.postEvent.initiate(
                Object.assign({ type, deviceId, sessionId }, finalProperties)
            )
        )
    } else if (n < 10) {
        setTimeout(() => {
            postInitEventWhenDeviceAndSession(type, finalProperties, n + 1)
        }, 500)
    }
}

const doTrackEvent = async (name: string, properties?: any, category: string = null) => {
    // ---- Traduction des properties ----
    const convertedProperties = convertProperties(properties)
    const pageAndProperties = getPageNameAndProperties()
    const convertedPageProperties = Object.assign(
        { page_view: pageAndProperties.name },
        pageAndProperties.properties
    )
    const convertedCategory = { category: category }

    // ---- Ajout des basics ----
    const finalProperties = convertFinalProperties(
        name,
        Object.assign(
            convertedCategory,
            getBasicProperties(),
            convertedPageProperties,
            convertedProperties
        )
    )

    // ---- Debug ----
    if (trackingDebug) {
        console.log('trackEvent', name, finalProperties)
    }

    // ---- Lancement de l'event ----
    if (areAnalyticsEnabled()) {
        if (areAnalyticsLoaded) {
            track(name, finalProperties)
        }
        if (store.getState().config.apiConfig?.enable_event) {
            switch (name) {
                case 'Init':
                    postInitEventWhenDeviceAndSession('INIT', finalProperties)
                    break
                case 'Item Added to cart':
                    postInitEventWhenDeviceAndSession('ADD_TO_CART', finalProperties)
                    break
                case 'More models Submitted':
                    postInitEventWhenDeviceAndSession('MORE_MODELS', finalProperties)
                    break
            }
        }
        if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
            // ---- En fonction de l'event ----
            let eventName = null,
                eventData = null
            switch (name) {
                case 'Init':
                    eventName = 'veesual.switch_model'
                    eventData = {}
                    break
                case 'Item Added to cart':
                    eventName = 'veesual.add_to_cart'
                    eventData = {
                        item_type: finalProperties.item_type,
                        item_price: finalProperties.item_price,
                        item_name: finalProperties.item_name,
                        item_size_selected_label: finalProperties.item_size_selected_label,
                        item_external_id: finalProperties.item_external_id,
                    }
                    break
            }
            if (eventName !== null && eventData !== null) {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `pa.sendEvent('${eventName.replace(
                            /'/,
                            "\\'"
                        )}', JSON.parse('${JSON.stringify(eventData).replace(/'/g, "\\'")}'));`,
                    },
                ])
            }
        }
        if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
            // ---- Envoi d'une liste restreinte d'events ----
            if (['Init', 'Item Added to cart'].indexOf(name) !== -1) {
                executePayloads([
                    {
                        type: 'event',
                        partner: ['contentsquare'],
                        payload: {
                            name: name,
                            properties: finalProperties,
                            timestamp: Date.now(),
                        },
                    },
                ])
            }
        }
        if (window.location.hostname.match(/(^|\.)claudiepierlot\.com$/)) {
            // ---- En fonction de l'event ----
            if (
                [
                    'Init',
                    'Item Added to cart',
                    'Swipe right',
                    'Swipe left',
                    'More models Visible',
                ].indexOf(name) !== -1
            ) {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `window.dataLayer = window.dataLayer || []; window.dataLayer.push({
                            'event': 'veesual_switchmodel_${name
                                .toLowerCase()
                                .replace(/\s+/g, '_')
                                .replace(/'/g, "\\'")}',
                            'veesual': JSON.parse('${JSON.stringify(finalProperties).replace(
                                /'/g,
                                "\\'"
                            )}')
                        });`,
                    },
                ])
            }

            // ---- Lancement du init dans contentsquare ----
            if (name == 'Init') {
                executePayloads([
                    {
                        type: 'javascript',
                        payload: `window._uxa = window._uxa || []; window._uxa.push(["trackDynamicVariable", {key: "Veesual Switch Model Init", value: "true"}]);`,
                    },
                ])
            }
        }
    }
}

const canUseAnalytics = () => {
    if (hasAnalytics()) {
        if (!areAnalyticsLoaded) {
            initTracking()
            doTrackEvent('Init', null, 'Switch Model')
        }
        return true
    }
    return false
}

export const trackPage = async () => {
    if (canUseAnalytics()) {
        doTrackPage()
    }
}

const eventQueue = []
export const trackEvent = async (name: string, properties?: any, category: string = null) => {
    if (store.getState().apiConfig) {
        if (canUseAnalytics()) {
            while (eventQueue.length) {
                const eventData = eventQueue.shift()
                doTrackEvent(eventData.name, eventData.properties, eventData.category)
            }
            doTrackEvent(name, properties, category)
        }
    } else {
        eventQueue.push({ name, properties, category })
    }
}
