import { Select } from 'antd'
import React, { useEffect } from 'react'
import { ReactFitty } from 'react-fitty'
import { MODAL_ROOT_ID } from '../utils/constant'
import { OptionInterface } from '../types/api-types'
import useCustomTranslation from '../shared_utils/translation'

const { Option } = Select

interface SelectProps {
    placeholder: string
    options: OptionInterface[]
    translation?: boolean
    name: string
    value?: any
    onChange?(e: any, name: string): void
    defaultOpen?: boolean
    customStyle?: any
    isFacet?: boolean
    showSearch?: boolean
}

const FilterSelect = (props: SelectProps) => {
    const { t } = useCustomTranslation()
    const {
        options,
        placeholder,
        name,
        value,
        defaultOpen = false,
        customStyle = {},
        isFacet,
        onChange,
        showSearch,
    } = props

    useEffect(() => {
        if (value) {
            const filteredOptions = options.filter((one) => one.value === value)
            if (filteredOptions.length !== 1 && onChange) {
                onChange(null, name)
            }
        }
        // eslint-disable-next-line
    }, [options, value])

    const getPopupContainer = () => {
        const root = document.getElementById(MODAL_ROOT_ID)
        return root || document.body
    }

    const inStock = (option: OptionInterface) => {
        if (typeof option.in_stock !== 'undefined') {
            return !!option.in_stock
        }
        return true
    }

    const filteredOptions = options.filter((one) => one.value === value)

    return (
        <>
            <Select
                placeholder={
                    options.length === 0 ? t('size_not_available_placeholder') : placeholder ?? ''
                }
                className='select select--container override_select_container'
                onClick={(e: any) => e.stopPropagation()}
                onChange={(e: any) => {
                    if (onChange) {
                        onChange(e, name)
                    }
                }}
                value={filteredOptions.length === 1 ? value : null}
                defaultOpen={defaultOpen}
                autoFocus={defaultOpen}
                getPopupContainer={getPopupContainer}
                style={customStyle}
                showAction={['focus', 'click']}
                showSearch={showSearch}
                disabled={options.length === 0}
            >
                {options.map((option, optionKey: number) => (
                    <Option
                        className='select--option override_select_option'
                        key={optionKey}
                        value={option.value}
                        disabled={!inStock(option)}
                    >
                        {!isFacet && (
                            <>
                                <div className='select--option-label override_select_option_label'>
                                    {option.label}
                                </div>

                                {option.extra && (
                                    <div className='select--option-extra override_select_option_extra'>
                                        <ReactFitty
                                            minSize={9}
                                            maxSize={14}
                                            style={{
                                                width: '100%',
                                                textAlign: 'right',
                                                verticalAlign: 'middle',
                                                overflow: 'hidden',
                                                textOverflow: 'ellipsis',
                                            }}
                                        >
                                            {option.extra}
                                        </ReactFitty>
                                    </div>
                                )}
                            </>
                        )}
                        {option.count && (
                            <div>{`${option.label ? option.label : option.value} (${
                                option.count
                            })`}</div>
                        )}
                    </Option>
                ))}
            </Select>
        </>
    )
}

export default FilterSelect
