import Icon from '@ant-design/icons/lib/components/Icon'
import { ReactComponent as CloseSvg } from '../assets/close.svg'
import { ReactComponent as SyncSvg } from '../assets/sync.svg'
import React from 'react'

export const getCloseIcon = () => {
    return <Icon component={CloseSvg} style={{ fontSize: 24 }} />
}

export const getSyncIcon = () => {
    return <Icon component={SyncSvg} style={{ fontSize: 20 }} />
}
