import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CompanyProfile } from '../../../types/api-types'

export interface RawConfig {
    [key: string]: string
}

export interface ConfigSlice {
    config: {
        [key: string]: string
    } | null
    queryParams: RawConfig | null
    modal: {
        visible: boolean
    }
    apiConfig: CompanyProfile | null
}

const initialState: ConfigSlice = {
    config: null,
    queryParams: null,
    modal: {
        visible: false,
    },
    apiConfig: null,
}

// Slice storing the config from data attribute
export const configSlice = createSlice({
    initialState,
    name: 'config',
    reducers: {
        addConfig: (state: ConfigSlice, action: PayloadAction<RawConfig>) => {
            state.config = action.payload
        },
        addApiConfig: (state: ConfigSlice, action: PayloadAction<CompanyProfile>) => {
            state.apiConfig = action.payload
        },
        addQueryParams: (state: ConfigSlice, action: PayloadAction<RawConfig>) => {
            state.queryParams = action.payload
        },
        changeModalVisible: (state: ConfigSlice, action: PayloadAction<boolean>) => {
            state.modal.visible = action.payload
        },
    },
})

// ----- Actions -----
export const { addConfig, changeModalVisible, addApiConfig, addQueryParams } = configSlice.actions

export default configSlice.reducer
