import { ZoomInOutlined } from '@ant-design/icons'
import { Image as AntdImg, Button, Spin } from 'antd'
import React, { useState, useEffect, useRef, useMemo } from 'react'
import LazyLoad from 'react-lazyload'
import { getSyncIcon } from '../utils/icon'
import { MODAL_ROOT_ID } from '../utils/constant'

interface ImageSmoothProps {
    src: string
    ratio: number
    cover?: boolean
    lazyload?: boolean
    transition?: boolean
    loader?: boolean
    loading?: boolean
    className?: string
    overflow?: boolean
    onClick?: (e: React.MouseEvent<HTMLElement>) => void
    enablePreview?: boolean
    fullSizeSrc?: string
    changeImage?(): void
    zoomImage?(): void
}

const ImageSmooth: React.FunctionComponent<ImageSmoothProps> = (props) => {
    const {
        src,
        ratio,
        cover = false,
        lazyload = true,
        transition = true,
        loader = false,
        loading = false,
        className = '',
        overflow = true,
        onClick,
        enablePreview,
        fullSizeSrc,
        changeImage,
        zoomImage,
    } = props
    const [currentSrc, setCurrentSrc] = useState<string | null>(null)
    const [currentLoaded, setCurrentLoaded] = useState(false)
    const [oldSrc, setOldSrc] = useState<string | null>(null)
    const [oldOpacity, setOldOpacity] = useState(0)
    const startTimeout: any = useRef()
    const transitionTimeout: any = useRef()
    const [previewVisible, setPreviewVisible] = useState<boolean>(false)

    const transitionTime = 500

    const handleOnLoad = (loadedSrc: string, force = false) => {
        if (loadedSrc === currentSrc || force) {
            setCurrentSrc(loadedSrc)
            setCurrentLoaded(true)
            startTimeout.current = setTimeout(() => {
                setOldOpacity(0)
                transitionTimeout.current = setTimeout(() => setOldSrc(null), transitionTime)
            }, 20)
        }
    }

    const handleOnClick = (e: React.MouseEvent<HTMLElement>) => {
        if (onClick) {
            onClick(e)
        }
    }

    const previewValue = useMemo(() => {
        return enablePreview
            ? {
                  visible: previewVisible,
                  onVisibleChange: () => setPreviewVisible(false),
                  src: fullSizeSrc || src,
                  mask: null,
                  toolbarRender: () => null,
                  getContainer: `#${MODAL_ROOT_ID}`,
              }
            : false
    }, [enablePreview, fullSizeSrc, src, previewVisible])

    useEffect(() => {
        const customHandleOnLoad = handleOnLoad.bind(this, src)

        clearTimeout(startTimeout.current)
        clearTimeout(transitionTimeout.current)
        ;(window as any).usePreloadImagesData = {}
        const img = new Image()
        if (src !== undefined && src !== null) {
            img.src = src as string
            ;(window as any).usePreloadImagesData[src] = img
            if (!img.complete) {
                //@ts-ignore
                img.addEventListener('load', customHandleOnLoad)
                //@ts-ignore
                img.addEventListener('error', customHandleOnLoad)
            }
            if (transition) {
                if (currentSrc !== null) {
                    setOldSrc(currentSrc)
                    setOldOpacity(0.99)
                }
            }

            setCurrentSrc(src)
            setCurrentLoaded(false)

            if (img.complete) {
                handleOnLoad(src, true)
            }
        }

        return () => {
            if (src !== undefined && src !== null && transition) {
                clearTimeout(startTimeout.current)
                clearTimeout(transitionTimeout.current)
                //@ts-ignore
                img.removeEventListener('load', customHandleOnLoad)
                //@ts-ignore
                img.removeEventListener('error', customHandleOnLoad)
            }
        }
        // eslint-disable-next-line
    }, [src])

    const handleZoom = () => {
        if (zoomImage) {
            zoomImage()
        }
        setPreviewVisible(true)
    }

    const isLoading = loading || src !== currentSrc || !currentLoaded

    return (
        <div
            className={`imagesmooth--container ${cover ? `imagesmooth--cover` : ''} ${className}`}
            style={{ paddingBottom: `${ratio}%` }}
            onClick={handleOnClick}
        >
            {currentSrc &&
                (lazyload ? (
                    <LazyLoad height='100%' offset={100} resize={true} overflow={overflow} once>
                        <AntdImg
                            className='imagesmooth--current'
                            src={currentSrc}
                            draggable='false'
                            preview={previewValue}
                        />
                    </LazyLoad>
                ) : (
                    <AntdImg
                        className='imagesmooth--current'
                        src={currentSrc}
                        draggable='false'
                        preview={previewValue}
                    />
                ))}
            {oldSrc && (
                <AntdImg
                    className='imagesmooth--old'
                    src={oldSrc}
                    draggable='false'
                    preview={previewValue}
                    style={Object.assign(
                        {
                            opacity: oldOpacity,
                        },
                        currentLoaded
                            ? {
                                  transition: `opacity ${transitionTime / 1000}s ease-out 0s`,
                              }
                            : {}
                    )}
                />
            )}
            {loader && isLoading && (
                <>
                    <div className='imagesmooth--loader-bg' />
                    <div className='imagesmooth--loader'>
                        <Spin size='large' />
                    </div>
                </>
            )}
            {enablePreview && (
                <div className='card--look-option'>
                    <div className='card--look-option-container'>
                        <Button
                            className='button--icon'
                            icon={<ZoomInOutlined style={{ fontSize: 20 }} />}
                            onClick={handleZoom}
                        />
                        {changeImage && (
                            <Button
                                className='button--icon'
                                icon={getSyncIcon()}
                                onClick={changeImage}
                            />
                        )}
                    </div>
                </div>
            )}
        </div>
    )
}

export default ImageSmooth
