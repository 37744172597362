import React from 'react'
import ReactDOM from 'react-dom/client'
import './styles/styles.scss'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { MODAL_ROOT_ID, ROOT_ID } from './utils/constant'

// ---- Check if Veesual Modal root doesn't exist ----
;[ROOT_ID, MODAL_ROOT_ID].forEach((rootId) => {
    if (!document.getElementById(rootId)) {
        // ---- Create Modal Elem and give the correct id ----
        const modalRootNode = document.createElement('div')
        modalRootNode.setAttribute('id', rootId)

        // ---- Insert our modal root at the end of the body elem ----
        document.body.append(modalRootNode)
    }
})

const root = ReactDOM.createRoot(document.getElementById(ROOT_ID) as HTMLElement)
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
