import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface LookSlice {
    imageIndex: number
}

const initialState: LookSlice = {
    imageIndex: 0,
}

// Slice storing data usefull for the look
export const lookSlice = createSlice({
    initialState,
    name: 'look',
    reducers: {
        changeImageIndex: (state: LookSlice, action: PayloadAction<number>) => {
            state.imageIndex = action.payload
        },
    },
})

// ----- Actions -----
export const { changeImageIndex } = lookSlice.actions

export default lookSlice.reducer
