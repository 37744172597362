import { useTranslation } from 'react-i18next'
import { useAppSelector } from '../services/store/store'

export default function useCustomTranslation() {
    const { t } = useTranslation()
    const internal = useAppSelector((state) => state.config.apiConfig?.internal)

    function translateWithOverride(label: string, option?: { [key: string]: string | undefined }) {
        if (internal) {
            return t([`${internal}.${label}`, label], option)
        }
        return t(label, option)
    }

    return { t: translateWithOverride }
}
