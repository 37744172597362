import { fetchBaseQuery } from '@reduxjs/toolkit/dist/query'
import { store } from '../store/store'
export function getCommonHeaders(): { [key: string]: string } {
    return {
        'Content-Type': 'application/json',
        'Accept-Language': 'fr',
    }
}

export const API_ENDPOINT: string = (
    process.env.API_ENDPOINT ||
    process.env.REACT_APP_API_ENDPOINT ||
    ''
).replace('[QUERY_HOSTNAME]', window.location.hostname)

const getConfigFromStore = () => {
    const configStore = store?.getState().config
    if (!configStore || !configStore.config) {
        return
    }

    return configStore.config
}

export const baseUrl = () => API_ENDPOINT || `https://api.veesual.ai`

// Basic RTK query function to make API calls
export const baseQuery = fetchBaseQuery({
    baseUrl: baseUrl(),
    prepareHeaders: (headers) => {
        const storeConfig = getConfigFromStore()
        headers.set('Content-Type', 'application/json')
        headers.set('Accept-Language', 'fr')
        if (storeConfig) {
            headers.set('Authorization', 'Bearer ' + storeConfig['api_key'])
        }

        return headers
    },
    headers: getCommonHeaders(),
})
