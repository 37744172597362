import { ConfigProvider, Modal, ThemeConfig } from 'antd'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import ModalSwitchModel from '../components/ModalSwitchModel'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../services/store/store'
import {
    RawConfig,
    addApiConfig,
    addConfig,
    addQueryParams,
    changeModalVisible,
} from '../services/store/slice/configSlice'
import { API_CONFIG_KEYS, MODAL_ROOT_ID, ROOT_ID } from '../utils/constant'
import { useGetConfigQuery } from '../services/api/api-config'
import { VeesualEvent } from '../types/api-types'
import { useGetModelsQuery } from '../services/api/api-model'
import i18n from '../i18n/config'

export default function Main() {
    const dispatch = useDispatch()
    const modalVisible = useAppSelector((state) => state.config.modal.visible)
    const storeConfig = useAppSelector((state) => state.config)
    const htmlOverflowRef: any = useRef()

    const [clicked, setClicked] = useState<boolean>(false)
    const [configTheme, setConfigTheme] = useState<ThemeConfig>()

    // ---- Building the api payload from the attributeConfig and the api config keys ----
    const apiConfigPayload = useMemo(() => {
        if (!storeConfig || !storeConfig.queryParams) {
            return null
        }

        const payload: RawConfig = {}

        API_CONFIG_KEYS.forEach((apiConfigKey) => {
            if (storeConfig.queryParams && storeConfig.queryParams[apiConfigKey]) {
                payload[apiConfigKey] = storeConfig.queryParams[apiConfigKey]
            }
        })

        return payload
    }, [storeConfig])

    const { data: apiConfig } = useGetConfigQuery(apiConfigPayload, { skip: !apiConfigPayload })

    const { isFetching } = useGetModelsQuery(storeConfig.queryParams, { skip: !clicked })

    const openModal = () => {
        dispatch(changeModalVisible(true))
        const htmlElement = document.getElementsByTagName('html')[0]
        htmlOverflowRef.current = htmlElement.style.overflowY || null
        htmlElement.style.overflowY = 'hidden'
    }

    const closeModal = () => {
        dispatch(changeModalVisible(false))
    }

    // ---- Revert the html changes when we close the modal ----
    useEffect(() => {
        if (!modalVisible) {
            const htmlElement = document.getElementsByTagName('html')[0]
            htmlElement.style.overflowY = htmlOverflowRef.current || 'unset'
        }
    }, [modalVisible])

    useEffect(() => {
        // ---- Function called when we got a click event on the veesual CTA ----
        const handleButtonClick = (e: VeesualEvent) => {
            if (!e.detail.config) {
                return
            }

            const configClone: RawConfig = JSON.parse(JSON.stringify(e.detail.config))
            const customParams: RawConfig = {}

            // ---- We delete fields that we don't need in Query default params ----
            const queryParamkeys = [
                'api_key',
                'api_prefix',
                'nb_models',
                'custom_style',
                'cta_location',
            ]
            queryParamkeys.forEach((paramKey: string) => {
                customParams[paramKey] = configClone[paramKey]
                delete configClone[paramKey]
            })

            // ---- We dispatch the config to the store ----
            dispatch(addConfig(customParams))
            dispatch(addQueryParams(configClone))

            // ---- i18n config -----
            if (configClone['locale']) {
                // ---- Check if we want to show the label with "label" key ----
                if (configClone['locale'] === 'label') {
                    i18n.changeLanguage('cimode')
                } else {
                    i18n.changeLanguage(configClone['locale'])
                }
            }

            setClicked(true)
        }

        // ---- Function called to ask if initiated ----
        const handleInitEvent = () => {
            window.dispatchEvent(new CustomEvent('veesual_switch_model_initiated'))
        }

        window.addEventListener('veesual_switch_model_event', ((e: VeesualEvent) =>
            handleButtonClick(e)) as EventListener)
        window.addEventListener('veesual_switch_model_init', handleInitEvent)
        handleInitEvent()

        return () => {
            window.removeEventListener('veesual_switch_model_event', ((e: VeesualEvent) =>
                handleButtonClick(e)) as EventListener)
            window.removeEventListener('veesual_switch_model_init', handleInitEvent)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    // ---- Dispatch apiConfig when we get it ----
    useEffect(() => {
        if (!apiConfig) {
            return undefined
        }

        dispatch(addApiConfig(apiConfig))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [apiConfig])

    useEffect(() => {
        if (!isFetching && clicked) {
            setClicked(false)
            openModal()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFetching, clicked])

    useEffect(() => {
        if (!storeConfig || !storeConfig.config) {
            return undefined
        }
        // ---- Get override style element if it exists ----
        const existingStyle = document.getElementById(ROOT_ID + '_style_override')

        // ---- Check if the custom_style exists in the config and no style_override exist yet ----
        if (storeConfig.config['custom_style'] && !existingStyle) {
            const style = document.createElement('style')

            if (storeConfig.config['custom_style'] === 'claudie_pierlot') {
                style.innerText =
                    '.override_card_container { box-shadow: none !important; }' +
                    '.ant-btn-primary { font-family: Oswald,Helvetica,Arial,sans-serif !important; font-weight: 700 !important; text-transform: uppercase !important; }' +
                    '.select--container { border: 1px solid black !important; box-shadow: none !important; }' +
                    '.ant-select-dropdown { border: 1px solid black !important; }'
            } else if (storeConfig.config['custom_style'] === 'moda_operandi') {
                style.innerText =
                    '#veesual_switch_model_root, #veesual_switch_model_modal_root { font-family: "Moda Operandi Sans", Arial, sans-serif }' +
                    '.ant-btn-primary { font-family: "Moda Operandi Sans", Arial, sans-serif !important; text-transform: uppercase !important; }'
            }

            style.id = ROOT_ID + '_style_override'

            document.head.appendChild(style)
        }
    }, [storeConfig])

    useEffect(() => {
        // ---- Primary color surchargee en url ----
        let primaryColor = 'black'
        let themeObject: ThemeConfig = {
            token: {
                // ---- Base theme ----
                colorPrimary: primaryColor,
                fontFamily: 'inherit',
            },
        }
        // ---- Apply default theme here ----
        setConfigTheme(themeObject)

        if (!apiConfig) {
            return undefined
        }

        // ---- Chargement du theme si present dans la config ----
        if (apiConfig.custom_view) {
            const { custom_colors } = apiConfig

            primaryColor = custom_colors.primary_color || primaryColor
            themeObject = {
                token: {
                    // ---- Base theme ----
                    colorPrimary: primaryColor,
                    fontFamily: 'inherit',

                    // ---- New style ----
                    colorLink: primaryColor,
                    colorInfo: custom_colors.info_color,
                    colorSuccess: custom_colors.success_color,
                    colorError: custom_colors.error_color,
                    colorWarning: custom_colors.warning_color,
                    borderRadius: 0,
                },
                components:
                    // ---- Custom component config for Claudie pierlot ----
                    apiConfig.internal === 'claudiepierlot-fr'
                        ? {
                              Checkbox: {
                                  // ---- Base theme ----
                                  colorPrimary: primaryColor,

                                  // ---- New style ----
                                  colorPrimaryBorder: primaryColor,
                                  colorBorder: primaryColor,
                              },
                              Select: {
                                  controlOutline: 'transparent',
                                  lineWidth: 0,
                                  colorBgContainerDisabled: 'white',
                                  colorTextDisabled: 'black',
                              },
                              Button: {
                                  borderColorDisabled: 'transparent',
                                  colorTextDisabled: 'black',
                              },
                              Input: {
                                  colorBorder: primaryColor,
                              },
                          }
                        : {
                              Checkbox: {
                                  // ---- Base theme ----
                                  colorPrimary: primaryColor,

                                  // ---- New style ----
                                  colorPrimaryBorder: primaryColor,
                                  colorBorder: primaryColor,
                              },
                              Select: {
                                  lineWidth: 0,
                              },
                              Input: {
                                  lineWidth: 0,
                              },
                          },
            }
        }

        // ---- Apply Theme ----
        setConfigTheme(themeObject)
    }, [apiConfig])

    return (
        <ConfigProvider theme={configTheme}>
            {storeConfig?.apiConfig?.override_sm_style && (
                <style
                    dangerouslySetInnerHTML={{
                        __html: storeConfig.apiConfig.override_sm_style,
                    }}
                ></style>
            )}
            <Modal
                className='modal'
                destroyOnClose
                open={modalVisible}
                centered={true}
                onCancel={() => closeModal()}
                footer={null}
                closable={false}
                getContainer={`#${MODAL_ROOT_ID}`}
                transitionName={apiConfig?.internal === 'claudiepierlot-fr' ? '' : undefined}
            >
                <ModalSwitchModel closeModal={closeModal} />
            </Modal>
        </ConfigProvider>
    )
}
