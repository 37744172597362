import React, { useMemo, useRef } from 'react'

import Slider from 'react-slick'

import { Look, Model } from '../types/api-types'
import CardModel from './CardModel'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'
import { trackEvent } from '../utils/tracking'
import CardMore from './CardMore'
import { useAppSelector } from '../services/store/store'

interface ArrowProps {
    className: string
    onClick?(): void
    style: React.CSSProperties
}

interface CarouselModelProps {
    models: Model[]
    look: Look
    itemType: string
    cardWidth?: number
    carouselMaxWidth?: number
    nbSlideShow: number
}

const CarouselModel = (props: CarouselModelProps) => {
    const { models, look, itemType, cardWidth, carouselMaxWidth, nbSlideShow } = props

    const config = useAppSelector((state) => state.config.apiConfig)

    const slider: any = useRef()
    const swipeTrackingEvent: any = useRef()

    const centerPaddingValue = useMemo(() => {
        if (!carouselMaxWidth || !cardWidth) {
            return 0
        }
        return carouselMaxWidth - cardWidth
    }, [cardWidth, carouselMaxWidth])

    const CarouselNextArrow = (arrowProps: ArrowProps) => {
        const { onClick } = arrowProps

        if (!onClick) {
            return
        }

        const handleClick = () => {
            swipeTrackingEvent.current = {
                name: 'Swipe right',
                properties: { swipe_from: 'arrow' },
                category: 'Modal',
            }
            onClick()
        }

        return (
            <div className={`carousel--arrow carousel--right`} onClick={handleClick}>
                <RightOutlined />
            </div>
        )
    }

    const CarouselPrevArrow = (arrowProps: ArrowProps) => {
        const { onClick } = arrowProps

        if (!onClick) {
            return
        }

        const handleClick = () => {
            swipeTrackingEvent.current = {
                name: 'Swipe left',
                properties: { swipe_from: 'arrow' },
                category: 'Modal',
            }
            onClick()
        }

        return (
            <div className={`carousel--arrow carousel--left`} onClick={handleClick}>
                <LeftOutlined />
            </div>
        )
    }

    const settings = {
        dots: nbSlideShow < models.length + (config?.enable_more_models ? 1 : 0),
        initialSlide: 0,
        infinite: false,
        slidesToShow: Math.min(5, models.length),
        slidesToScroll: Math.min(5, models.length),
        touchThreshold: 15,
        speed: 200,
        arrow: true,
        //@ts-ignore
        nextArrow: <CarouselNextArrow />,
        //@ts-ignore
        prevArrow: <CarouselPrevArrow />,
        onSwipe: (direction: string) => {
            const adaptedDirection = direction == 'right' ? 'left' : 'right'
            swipeTrackingEvent.current = {
                name: `Swipe ${adaptedDirection}`,
                properties: { swipe_from: 'image' },
                category: 'Modal',
            }
        },
        beforeChange: (current: number, next: number) => {
            // ---- Unfocus if we are focused in the more Card which caused bug on mobile ----
            ;(document.activeElement as HTMLElement)?.blur()

            // ---- Only if we moved ----
            if (current != next) {
                // ---- Check if the last slide is visible ---
                if (next + nbSlideShow === models.length + 1 && config?.enable_more_models) {
                    trackEvent(
                        `More models Visible`,
                        [
                            look,
                            { item_type: itemType.toUpperCase() },
                            swipeTrackingEvent.current
                                ? swipeTrackingEvent.current.properties
                                : null,
                        ],
                        'Modal'
                    )
                }

                // ---- Si on a un event a envoyer ----
                if (swipeTrackingEvent.current) {
                    trackEvent(
                        swipeTrackingEvent.current.name,
                        [
                            look,
                            next < models.length ? models[next] : null,
                            { item_type: itemType.toUpperCase() },
                            swipeTrackingEvent.current.properties,
                        ],
                        swipeTrackingEvent.current.category
                    )
                }
            }
            if (swipeTrackingEvent.current) {
                swipeTrackingEvent.current = null
            }
        },
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    centerMode: true,
                    centerPadding: `${centerPaddingValue / 2}px`,
                },
            },
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: Math.min(3, models.length),
                    slidesToScroll: Math.min(3, models.length),
                },
            },
            {
                breakpoint: 1680,
                settings: {
                    slidesToShow: Math.min(5, models.length),
                    slidesToScroll: Math.min(5, models.length),
                },
            },
        ],
    }

    return (
        <div
            style={
                carouselMaxWidth
                    ? {
                          maxWidth: carouselMaxWidth,
                          width: Math.min(
                              carouselMaxWidth,
                              window.innerWidth - (window.innerWidth <= 768 ? 0 : 32)
                          ),
                          height: '100%',
                      }
                    : { width: '100%', height: '100%' }
            }
            id='switchModelCarouselContainer'
        >
            <Slider
                ref={(slick) => (slider.current = slick)}
                {...settings}
                className={`carousel--container${
                    nbSlideShow >= models.length + (config?.enable_more_models ? 1 : 0)
                        ? ' carousel--container-no-swipe'
                        : ''
                }`}
            >
                {models.length > 0 &&
                    models.map((item, itemKey: number) => (
                        <CardModel
                            model={item}
                            look={look}
                            itemType={itemType}
                            key={itemKey}
                            cardWidth={cardWidth}
                            ratio={100 / (config?.look_image_ratio || 0.66)}
                            enablePreview={true}
                        />
                    ))}
                {models.length > 0 && config?.enable_more_models && (
                    <CardMore
                        cardWidth={cardWidth}
                        models={models}
                        onFinishedClick={() => slider.current.slickGoTo(0)}
                    />
                )}
            </Slider>
        </div>
    )
}

export default CarouselModel
