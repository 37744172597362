import React, { useEffect, useMemo, useState } from 'react'
import { useGetModelsQuery } from '../services/api/api-model'
import CarouselModel from './CarouselModel'
import { useAppSelector } from '../services/store/store'
import { getCloseIcon } from '../utils/icon'
import { Button } from 'antd'
import { trackEvent } from '../utils/tracking'
import useCustomTranslation from '../shared_utils/translation'
import { calcMaxWidth } from '../utils/calc-max-width'
import { ApiCustomError } from '../types/api-types'

interface ModalSwitchModelProps {
    closeModal(): void
}

export default function ModalSwitchModel(props: ModalSwitchModelProps) {
    const storeConfig = useAppSelector((state) => state.config)
    const { data: modelResponse, error: errorModel } = useGetModelsQuery(storeConfig.queryParams)
    const { t } = useCustomTranslation()

    const { closeModal } = props

    const [nbSlideShow, setNbSlideShow] = useState<number>(5)
    const [cardWidth, setCardWidth] = useState<number>()
    const [carouselMaxWidth, setCarouselMaxWidth] = useState<number>()

    const { models, look, itemType } = useMemo(() => {
        // ---- Recuperation de l'item type ----
        let configItemType = undefined
        if (storeConfig.queryParams && storeConfig.queryParams.focus) {
            configItemType = storeConfig.queryParams.focus.toLowerCase()
        } else {
            for (const key in storeConfig.queryParams) {
                const match = key.match(/^([a-z]+)_garment_id$/)
                if (match) {
                    configItemType = match[1]
                    break
                }
            }
        }

        // ---- Recuperation des models et du look ----
        let dataModels = undefined,
            dataLook = undefined
        if (modelResponse && storeConfig.config) {
            dataModels = modelResponse.items
            dataLook = modelResponse.look

            // ---- We fetch the value of nb_models and slice if we the response is bigger than the restriction ----
            const nbModels = parseInt(storeConfig.config['nb_models']) || 0
            if (nbModels > 0 && modelResponse.items.length > nbModels) {
                dataModels = modelResponse.items.slice(0, nbModels)
            }
        }

        return { models: dataModels, look: dataLook, itemType: configItemType }
    }, [modelResponse, storeConfig])

    useEffect(() => {
        if (look && itemType && storeConfig.apiConfig) {
            trackEvent('Open Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
        }
    }, [look, itemType, storeConfig.apiConfig])

    // ---- Setup resize listener for max width of image ----
    useEffect(() => {
        let interval = 0
        function handleResize() {
            const slideElem = document.getElementsByClassName('slick-slide')[0]
            const descriptionElems = document.getElementsByClassName('card-carousel--text')
            if (
                slideElem &&
                descriptionElems &&
                descriptionElems.length > 0 &&
                models &&
                storeConfig.apiConfig
            ) {
                clearInterval(interval)

                // ---- Getting the bigger description in all descriptions ----
                let biggerDescriptionHeight = 0
                for (let i = 0; i < descriptionElems.length; i++) {
                    const item = descriptionElems.item(i)
                    if (item && item.clientHeight > biggerDescriptionHeight) {
                        biggerDescriptionHeight = item.clientHeight
                    }
                }

                // ---- We get the correct slideToShow according to window size ----
                let currentNbSlideShow = nbSlideShow
                switch (true) {
                    case window.innerWidth > 1024:
                        currentNbSlideShow = Math.min(5, models.length)
                        break
                    case window.innerWidth > 768:
                        currentNbSlideShow = Math.min(3, models.length)
                        break
                    default:
                        currentNbSlideShow = 1
                }

                // ---- Elements needed for correct offset ----
                const titleElements = document.getElementsByClassName('modal--header')
                const cardElements = document.getElementsByClassName('card-carousel--container')
                const cardMarginValue =
                    parseInt(
                        window.getComputedStyle(cardElements[0]).marginLeft.replace('px', '')
                    ) * 2

                // ---- Max Width calc ----
                const maxWidth = calcMaxWidth(
                    (window.innerWidth -
                        32 -
                        (cardElements.length > 0 ? cardMarginValue : 16) *
                            Math.min(currentNbSlideShow, models.length)) /
                        Math.min(currentNbSlideShow, models.length),
                    0,
                    window.innerHeight - 32,
                    storeConfig.apiConfig.look_image_ratio || 0.66,
                    // bonus padding for max height + carousel padding + dots + bottom card + card margin + title
                    (titleElements.length > 0 ? titleElements[0].clientHeight : 43) + // title
                        (currentNbSlideShow >=
                        models.length + (storeConfig.apiConfig.enable_more_models ? 1 : 0)
                            ? 8
                            : 32) + // dots
                        155 + // bottom except description
                        biggerDescriptionHeight, // description
                    0,
                    0
                )
                setCardWidth(maxWidth)

                // ---- We calculate the max width of the carousel according to currentNbSlideShow and cardWidth ----
                if (nbSlideShow !== currentNbSlideShow) {
                    setNbSlideShow(currentNbSlideShow)
                }
                const currentCarouselMaxWidth =
                    currentNbSlideShow === 1
                        ? window.innerWidth
                        : Math.min(
                              window.innerWidth,
                              maxWidth * Math.min(currentNbSlideShow, models.length) + 32
                          )
                setCarouselMaxWidth(currentCarouselMaxWidth)
            }
        }

        interval = window.setInterval(() => {
            handleResize()
        }, 100)
        window.addEventListener('resize', handleResize)
        return () => {
            window.removeEventListener('resize', handleResize)
        }
        // eslint-disable-next-line
    }, [])

    useEffect(() => {
        // ---- Pour envoyer un close modal avec data, on se base sur l'unmount ----
        return () => {
            if (look && itemType) {
                trackEvent('Close Modal', [look, { item_type: itemType.toUpperCase() }], 'Main')
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <div
            className='modal--container'
            style={{
                opacity: (cardWidth && carouselMaxWidth) || errorModel ? 1 : 0,
                ...(errorModel ? { width: '50vw', height: '50vh' } : null),
            }}
        >
            <div className='modal--header'>
                <h2 className='modal--title'>
                    {t('title')} {models ? `(${models.length})` : ''}
                </h2>
                {
                    <Button
                        className='modal--close'
                        onClick={closeModal}
                        icon={getCloseIcon()}
                        type='link'
                    />
                }
            </div>

            <div className='modal--content'>
                {errorModel && (
                    <div style={{ textAlign: 'center' }}>
                        <div style={{ fontWeight: 'bold' }}>{t('error')}</div>
                        <div>{(errorModel as ApiCustomError)?.data?.message}</div>
                    </div>
                )}
                {models && look && itemType && (
                    <CarouselModel
                        models={models}
                        look={look}
                        itemType={itemType}
                        nbSlideShow={nbSlideShow}
                        cardWidth={cardWidth}
                        carouselMaxWidth={carouselMaxWidth}
                    />
                )}
            </div>
        </div>
    )
}
