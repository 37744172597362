import { createSlice, PayloadAction } from '@reduxjs/toolkit'

export interface ModelSlice {
    garmentSizeIndex: number | null
}

const initialState: ModelSlice = {
    garmentSizeIndex: null,
}

// Slice storing data usefull for the Models
export const modelSlice = createSlice({
    initialState,
    name: 'model',
    reducers: {
        changeGarmentSizeIndex: (state: ModelSlice, action: PayloadAction<number | null>) => {
            state.garmentSizeIndex = action.payload
        },
    },
})

// ----- Actions -----
export const { changeGarmentSizeIndex } = modelSlice.actions

export default modelSlice.reducer
