import { executePayloads } from './execute'
import { Garment } from '../types/api-types'

const safeJsonParse = (jsonString: string) => {
    try {
        const parsedDatas = JSON.parse(jsonString)
        return parsedDatas
    } catch (e) {
        return null
    }
}

export const addGarmentSizesToCart = (params: any[], callback: (success: boolean) => void) => {
    // ---- Debug ----
    for (const one of params) {
        console.log(one.garment.garment_id, one.currentSize)
    }

    // ---- Gemo ----
    if (window.location.hostname.match(/(^|\.)gemo\.fr$/)) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { garment, currentSize } = one

            // ---- Recuperation du variation_id ----
            let offer_id = null
            for (const size of garment.product_sizes) {
                if (size.value == currentSize && typeof size.offer_id !== 'undefined') {
                    offer_id = size.offer_id
                }
            }

            // ---- Url de post ----
            const postUrl = '/ajax.V1.php/fr_FR/Rbs/Commerce/Cart/Lines'

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'Content-Type': 'application/json',
                        Accept: 'application/json, text/plain, */*',
                        'X-HTTP-Method-Override': 'POST',
                    },
                    dataType: 'json',
                    data: {
                        websiteId: 100199,
                        sectionId: 112008,
                        pageId: 100718,
                        data: {
                            lines: [
                                {
                                    productId: parseInt(offer_id),
                                    category: 'shippingCategories',
                                    successModal: {
                                        pageFunction: 'Rbs_Catalog_ProductAddedToCart',
                                        themeName: 'Project_Gemotheme',
                                    },
                                    storeId: 0,
                                },
                            ],
                            clientDate: new Date().toString(),
                        },
                        detailed: false,
                        URLFormats: 'canonical',
                        visualFormats: 'shortCartItem',
                        referer: window.location,
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        executePayloads(payloads, (responses) => {
            if (responses === false) {
                return callback(false)
            }
            const response = responses.pop()
            const parsedResponse = safeJsonParse(response)
            if (
                !parsedResponse ||
                !parsedResponse.dataSets ||
                !parsedResponse.dataSets.linesAdded ||
                !parsedResponse.dataSets.linesAdded.length ||
                !parsedResponse.dataSets.common ||
                !parsedResponse.dataSets.common.lineCount
            ) {
                return callback(false)
            }
            executePayloads([
                {
                    type: 'javascript',
                    payload: `jQuery('.badge').text('${parsedResponse.dataSets.common.lineCount}');`,
                },
            ])
            return callback(true)
        })
    }
    // ---- Claudie Pierlot ----
    else if (window.location.hostname.match(/(^|\.)claudiepierlot\.com$/)) {
        // ---- Initialisation ----
        const payloads = []

        // ---- Pour chaque param ----
        for (const one of params) {
            // ---- Raccourci ----
            const { currentSize } = one

            // ---- Url en fonction de l'origin ----
            let postUrl = '/on/demandware.store/Sites-Claudie-FR-Site/fr_FR/Cart-AddProduct'
            if (window.location.pathname.match(/^\/es\//)) {
                postUrl = '/on/demandware.store/Sites-Claudie-ES-Site/es/Cart-AddProduct'
            }

            // ---- Ajout au payloads ----
            payloads.push({
                type: 'request',
                payload: {
                    method: 'POST',
                    url: postUrl,
                    headers: {
                        'X-Requested-With': 'XMLHttpRequest',
                    },
                    data: {
                        pid: currentSize,
                        quantity: '1',
                        options: '[]',
                    },
                },
            })
        }

        // ---- Lancement du message avec callback ----
        executePayloads(payloads, (responses) => {
            if (responses === false) {
                return callback(false)
            }
            const response = responses.pop()
            const parsedResponse = safeJsonParse(response)
            if (
                !parsedResponse ||
                parsedResponse.error ||
                !parsedResponse.cart ||
                !parsedResponse.cart.numItems
            ) {
                return callback(false)
            }
            executePayloads([
                {
                    type: 'javascript',
                    payload: `jQuery('.minicart-quantity').text('${parsedResponse.cart.numItems}');`,
                },
            ])
            return callback(true)
        })
    }
    // ---- Default ----
    else {
        setTimeout(() => callback(true), 2000)
    }
}

export const addGarmentSizeToCart = (
    garment: Garment,
    currentSize: string,
    callback: (success: boolean) => void
) => {
    addGarmentSizesToCart([{ garment, currentSize }], callback)
}
