import { Button, Card, Typography } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import { GarmentSize, Look, Model, OptionInterface } from '../types/api-types'
import { resizeImage } from '../utils/image'
import ImageSmooth from './ImageSmooth'
import FilterSelect from './filter'
import { useAppSelector } from '../services/store/store'
import { useDispatch } from 'react-redux'
import { changeImageIndex } from '../services/store/slice/lookSlice'
import { changeModalVisible } from '../services/store/slice/configSlice'
import { trackEvent } from '../utils/tracking'
import { getGarmentSizeLabel, getModelMatchingGarmentSize } from '../shared_utils/garment-size'
import { changeGarmentSizeIndex } from '../services/store/slice/modelSlice'
import { addGarmentSizeToCart } from '../shared_utils/addtocart'
import useCustomTranslation from '../shared_utils/translation'

const { Title, Paragraph } = Typography

interface CardModelProps {
    model: Model
    look: Look
    itemType: string
    cardWidth?: number
    ratio?: number
    enablePreview?: boolean
}

export default function CardModel(props: CardModelProps) {
    const { model, look, itemType, cardWidth, ratio, enablePreview } = props

    const dispatch = useDispatch()
    const { t } = useCustomTranslation()

    const lookImageIndex = useAppSelector((state) => state.look.imageIndex)
    const modelGarmentSizeIndex = useAppSelector((state) => state.model.garmentSizeIndex)
    const config = useAppSelector((state) => state.config.apiConfig)

    const [sizeValue, setSizeValue] = useState<string>(
        getModelMatchingGarmentSize(model.model_garment_sizes || [], look[itemType].product_sizes)
            ?.value || ''
    )

    const garmentSizeFromModel = useMemo(() => {
        if (model && model.model_garment_sizes) {
            return getModelMatchingGarmentSize(
                model.model_garment_sizes,
                look[itemType].product_sizes
            )
        }
    }, [model, itemType, look])

    const modelImage = useMemo(() => {
        if (model.image_urls && model.image_urls.length >= lookImageIndex) {
            return model.image_urls[lookImageIndex]
        }

        return model.image_url
    }, [lookImageIndex, model])

    const modelDescription = useMemo(() => {
        if (!model) {
            return ''
        }

        let modelGarmentSize = model.model_garment_sizes
            ? garmentSizeFromModel?.label
            : model.model_garment_size

        if (!modelGarmentSize) {
            if (model.model_garment_sizes) {
                modelGarmentSize = model.model_garment_sizes[modelGarmentSizeIndex || 0]
            }
        } else {
            const foundIndex = model.model_garment_sizes?.findIndex(
                (size) => size === modelGarmentSize
            )
            dispatch(changeGarmentSizeIndex(foundIndex === undefined ? null : foundIndex))
        }

        return (
            model.model_description ||
            t('model_description', {
                modelName: model.model_display || model.model_name,
                modelHeight: `${Math.floor(parseInt(model.model_height) / 100)}m${
                    parseInt(model.model_height) % 100
                }`,
                modelSize: modelGarmentSize,
            })
        )
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model, garmentSizeFromModel])

    const selectOptions = useMemo(() => {
        if (!look[itemType]?.product_sizes) {
            return []
        }

        const baseOptions: OptionInterface[] = look[itemType].product_sizes.map(
            (size: GarmentSize) => {
                return {
                    label: size.label,
                    value: size.value,
                    extra:
                        size.value === garmentSizeFromModel?.value
                            ? t('worn_by', { modelName: model.model_display || model.model_name })
                            : '',
                }
            }
        )

        return baseOptions
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [look, itemType, model, garmentSizeFromModel])

    const handleSizeChange = (newSize: string) => {
        if (newSize) {
            trackEvent(
                'Size Selected',
                [
                    look[itemType],
                    model,
                    {
                        item_size_selected: newSize,
                        item_size_selected_label: newSize,
                    },
                ],
                'Card'
            )
        }

        // ---- Check on every selectContainerElements if we have the placeholder and remove it if it exists ----
        const selectContainerElements = document.getElementsByClassName(
            `cardModelSizeSelect-${model.model_id}`
        )
        for (let i = 0; i < selectContainerElements.length; i++) {
            const selectElement =
                selectContainerElements[i].getElementsByClassName('ant-select-selector')[0]

            // ---- Remove unavailable placeholder if it exists ----
            const unavailableElement = selectElement.getElementsByClassName(
                'select--unavailable-placeholder'
            )[0]
            if (unavailableElement) {
                unavailableElement.remove()
            }
        }

        setSizeValue(newSize)
    }

    const handleAddToCart = () => {
        trackEvent(
            'Item Added to cart',
            [
                look[itemType],
                model,
                {
                    item_size_selected: sizeValue,
                    item_size_selected_label: getGarmentSizeLabel(
                        sizeValue,
                        look[itemType].product_sizes
                    ),
                },
            ],
            'Card'
        )
        addGarmentSizeToCart(look[itemType], sizeValue, (success: boolean) => {
            if (success) {
                dispatch(changeModalVisible(false))
            } else {
                alert('There was a problem while adding to cart')
            }
        })
    }

    const handleChangeImage = () => {
        trackEvent(
            'Back Changed',
            [
                look[itemType],
                model,
                { back_value: lookImageIndex === 1 ? 'on' : 'off', back_type: 'icon' },
            ],
            'Card'
        )
        dispatch(changeImageIndex(lookImageIndex === 1 ? 0 : 1))
    }

    const handleZoomImage = () => {
        trackEvent(
            'Zoom Opened',
            [
                look[itemType],
                model,
                { back_value: lookImageIndex === 1 ? 'on' : 'off', zoom_type: 'icon' },
            ],
            'Card'
        )
    }

    useEffect(() => {
        if (!selectOptions) {
            return undefined
        }

        let intervalId = 0
        // ---- Add a custom placeholder if the modelSize is not available ----
        if (
            modelGarmentSizeIndex !== null &&
            model.model_garment_sizes &&
            model.model_garment_sizes[modelGarmentSizeIndex] &&
            selectOptions.length !== 0 &&
            selectOptions.findIndex(
                (option) =>
                    option.label === (model.model_garment_sizes as string[])[modelGarmentSizeIndex]
            ) === -1
        ) {
            // ---- We need the interval in case the selectContainer is not yet loaded ----
            intervalId = window.setInterval(() => {
                const selectContainerElements = document.getElementsByClassName(
                    `cardModelSizeSelect-${model.model_id}`
                )

                // ---- If the list is empty we wait for next interval ----
                if (selectContainerElements.length === 0) {
                    return
                }

                clearInterval(intervalId)

                // ---- Add the element in every selectContainer as there can be multiple because of carousel infinite ----
                for (let i = 0; i < selectContainerElements.length; i++) {
                    const selectElement =
                        selectContainerElements[i].getElementsByClassName('ant-select-selector')[0]
                    if (
                        selectElement?.getElementsByClassName('select--unavailable-placeholder')
                            .length === 0 &&
                        model.model_garment_sizes
                    ) {
                        const sizeSpan = document.createElement('span')
                        sizeSpan.textContent = model.model_garment_sizes[modelGarmentSizeIndex]
                        sizeSpan.className = 'select--unavailable-placeholder'
                        selectElement?.prepend(sizeSpan)
                    }
                }
            }, 100)
        }
        return () => {
            clearInterval(intervalId)
        }
    }, [selectOptions, model, modelGarmentSizeIndex])

    return (
        <Card
            className='card-carousel card-carousel--container override_card_container'
            hoverable={window.innerWidth > 768}
            cover={
                <div className='card--image-container'>
                    <div className={'card--image'}>
                        <ImageSmooth
                            overflow={false}
                            ratio={ratio || 166}
                            src={resizeImage(modelImage, { width: 800 })}
                            transition={config?.internal !== 'claudiepierlot-fr'}
                            lazyload={false}
                            loader={true}
                            cover={true}
                            enablePreview={enablePreview}
                            fullSizeSrc={modelImage}
                            changeImage={
                                model.image_urls && model.image_urls.length > 1
                                    ? handleChangeImage
                                    : undefined
                            }
                            zoomImage={handleZoomImage}
                        />
                    </div>
                </div>
            }
            style={cardWidth ? { width: cardWidth } : {}}
        >
            <div className='card-carousel--body-container'>
                <Title
                    ellipsis={{
                        rows: 1,
                    }}
                    className='card-carousel--title override_card_carousel_title'
                >
                    {model.model_display || model.model_name}
                </Title>
                <Paragraph
                    ellipsis={{
                        rows: 3,
                        tooltip: true,
                    }}
                    className='card-carousel--text'
                >
                    {modelDescription}
                </Paragraph>
            </div>

            <div className={`card-carousel--cta-container cardModelSizeSelect-${model.model_id}`}>
                <FilterSelect
                    name='size'
                    placeholder={t('size_not_available')}
                    options={selectOptions}
                    customStyle={{ width: '100%' }}
                    onChange={handleSizeChange}
                    value={sizeValue}
                />

                {selectOptions.length === 0 ? (
                    <Button type='primary' style={{ width: '100%' }} disabled={true}>
                        {t('not_available')}
                    </Button>
                ) : (
                    <Button type='primary' style={{ width: '100%' }} onClick={handleAddToCart}>
                        {t('add_to_cart')}
                    </Button>
                )}
            </div>
        </Card>
    )
}
