import { Card, Col } from 'antd'
import React, { useEffect, useMemo, useState } from 'react'
import FormMore from './FormMore'
import { Model } from '../types/api-types'

interface CardMoreProps {
    cardWidth?: number
    models: Model[]
    onFinishedClick?(): void
}

export default function CardMore(props: CardMoreProps) {
    const { cardWidth, models, onFinishedClick } = props

    const [moreLookIndex, setMoreLookIndex] = useState<number>(0)

    const looksUrls = useMemo(() => {
        if (!models) {
            return null
        }

        return models.map((model) => (model.image_urls ? model.image_urls[0] : model.image_url))
    }, [models])

    useEffect(() => {
        const timeoutId = setTimeout(() => {
            if (moreLookIndex + 1 === looksUrls?.length) {
                return setMoreLookIndex(0)
            }

            return setMoreLookIndex(moreLookIndex + 1)
        }, 3000)

        return () => {
            clearTimeout(timeoutId)
        }
    }, [moreLookIndex, looksUrls])

    return (
        <Card
            className='card-carousel card-carousel--more-container override_card_container'
            style={cardWidth ? { width: cardWidth } : {}}
        >
            <Col className='card-carousel--more-body'>
                <FormMore onFinishedClick={onFinishedClick} />
            </Col>
        </Card>
    )
}
