import React, { useEffect } from 'react'
import { OptionInterface } from '../types/api-types'
import { DownOutlined } from '@ant-design/icons'

interface NativeSelectProps {
    placeholder: string
    options: OptionInterface[]
    name: string
    value?: any
    onChange?(e: any, name: string): void
    customStyle?: any
}

export default function NativeSelect(props: NativeSelectProps) {
    const { placeholder, options, name, value, onChange, customStyle } = props

    useEffect(() => {
        if (value) {
            const filteredOptions = options.filter((one) => one.value === value)
            if (filteredOptions.length !== 1 && onChange) {
                onChange(null, name)
            }
        }
        // eslint-disable-next-line
    }, [options, value])

    const filteredOptions = options.filter((one) => one.value === value)

    return (
        <div style={{ position: 'relative' }}>
            <DownOutlined className='native-select--arrow' />
            <select
                className='native-select--container override_native_select_container'
                style={customStyle}
                value={filteredOptions.length === 1 ? value : ''}
                onChange={(e: any) => {
                    if (onChange) {
                        onChange(e, name)
                    }
                }}
            >
                <option value=''>{placeholder ?? ''}</option>
                {options.map((option, optionKey: number) => (
                    <option key={optionKey} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    )
}
