import React from 'react'
import { Provider } from 'react-redux'
import { store } from './services/store/store'
import Main from './page/Main'
import './i18n/config'

// ---- Copy of slick css parsed by postcss ----
import './styles/.postcss/slick/slick.css'
import './styles/.postcss/slick/slick-theme.css'

function App() {
    return (
        <>
            <Provider store={store}>
                <Main />
            </Provider>
        </>
    )
}

export default App
