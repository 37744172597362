import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'

import enTranslation from './en/translation.json'
import frTranslation from './fr/translation.json'
import deTranslation from './de/translation.json'
import esTranslation from './es/translation.json'

const resources = {
    en: {
        translation: enTranslation,
    },
    en_gb: {
        translation: enTranslation,
    },
    fr: {
        translation: frTranslation,
    },
    de: {
        translation: deTranslation,
    },
    es: {
        translation: esTranslation,
    },
} as const

const fallback = 'fr'

i18n
    // detect user language
    // learn more: https://github.com/i18next/i18next-browser-languageDetector
    .use(LanguageDetector)
    // pass the i18n instance to react-i18next.
    .use(initReactI18next)
    // init i18next
    // for all options read: https://www.i18next.com/overview/configuration-options
    .init({
        fallbackLng: fallback,
        resources: resources,
        interpolation: {
            escapeValue: false,
        },
    })

export default i18n
