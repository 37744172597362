import { Button, Col, Form, Input, Radio, Space } from 'antd'
import Paragraph from 'antd/es/typography/Paragraph'
import Title from 'antd/es/typography/Title'
import React, { ChangeEvent, useMemo, useState } from 'react'
import { trackEvent } from '../utils/tracking'
import NativeSelect from './NativeSelect'
import useCustomTranslation from '../shared_utils/translation'

type FieldType = {
    height?: string
    topSize?: string
    bottomSize?: string
    isInterestedOwnBody: number
}

interface FormMoreProps {
    onFinishedClick?(): void
}

export default function FormMore(props: FormMoreProps) {
    const { t } = useCustomTranslation()

    const { onFinishedClick } = props

    const [finishedForm, setFinishedForm] = useState<boolean>(false)
    const [heightValue, setHeightValue] = useState<string>('')
    const [isHeightInvalid, setIsHeightInvalid] = useState<boolean>(false)

    const onFinish = (values: FieldType) => {
        if (isHeightInvalid) {
            return
        }

        if (heightValue === '') {
            return setIsHeightInvalid(true)
        }
        setFinishedForm(true)
        trackEvent(
            'More models Submitted',
            {
                form_height: heightValue,
                form_top_size: values.topSize,
                form_bottom_size: values.bottomSize,
                form_upload_photo: values.isInterestedOwnBody,
            },
            'Card'
        )
    }

    // ---- Hardcoded Sizes array ----
    const topSizes = Array.from({ length: 18 }, (_, index) => 30 + index * 2)

    // ---- Options array for select ----
    const topOptions = useMemo(() => {
        return topSizes.map((size) => ({
            label: size.toString(),
            value: size.toString(),
        }))
    }, [topSizes])

    const handleHeightChange = (e: ChangeEvent) => {
        // ---- Need to cast as HTMLInputElement ----
        const inputValue = (e.target as HTMLInputElement).value

        // ---- Replace and store the new handled value ----
        const returnValue = inputValue.replaceAll(/[^0-9]/gi, '')
        setHeightValue(returnValue)

        // ---- Converted number to handle value ----
        const parsedValue = parseInt(returnValue)

        // ---- Error handling ----
        if (returnValue === '' || parsedValue < 100 || parsedValue > 230) {
            setIsHeightInvalid(true)
        } else {
            setIsHeightInvalid(false)
        }
    }

    const handleReturnClick = () => {
        trackEvent('More models Closed', null, 'Card')
        if (onFinishedClick) {
            onFinishedClick()
        }
    }

    return (
        <div
            className='form-more--container'
            style={finishedForm ? { justifyContent: 'center' } : {}}
        >
            {finishedForm ? (
                <Col className='form-more--success-container'>
                    <Title className='form-more--title'>{t('more_form.submit_success')}</Title>
                    <Button type='primary' onClick={handleReturnClick}>
                        {t('more_form.return')}
                    </Button>
                </Col>
            ) : (
                <>
                    <div>
                        <Title className='form-more--title'>{t('more_form.title')}</Title>
                        <Paragraph className='form-more--description'>
                            {t('more_form.description')}
                        </Paragraph>
                    </div>
                    <Form
                        name='moreModel'
                        className='form-more--form'
                        autoComplete='off'
                        onFinish={onFinish}
                        layout='vertical'
                        initialValues={{ isInterestedOwnBody: 0 }}
                    >
                        <div className='form-more--form-input-container'>
                            <Form.Item<FieldType>>
                                <Input
                                    className='input--more'
                                    placeholder={t('more_form.height_placeholder')}
                                    value={heightValue}
                                    onChange={handleHeightChange}
                                    style={isHeightInvalid ? { border: '1px solid red' } : {}}
                                    suffix={<div className='input--suffix'>cm</div>}
                                    type='tel'
                                />
                                {isHeightInvalid && (
                                    <div style={{ color: 'red' }}>
                                        {t('more_form.height_error')}
                                    </div>
                                )}
                            </Form.Item>

                            <Form.Item<FieldType> name='topSize' valuePropName='value'>
                                <NativeSelect
                                    name='topSize'
                                    placeholder={t('more_form.top_placeholder')}
                                    options={topOptions}
                                    customStyle={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item<FieldType> name='bottomSize' valuePropName='value'>
                                <NativeSelect
                                    name='bottomSize'
                                    placeholder={t('more_form.bottom_placeholder')}
                                    options={topOptions}
                                    customStyle={{ width: '100%' }}
                                />
                            </Form.Item>

                            <Form.Item<FieldType>
                                name='isInterestedOwnBody'
                                valuePropName='value'
                                label={t('more_form.interested_own_body.title')}
                            >
                                <Radio.Group>
                                    <Space direction='vertical'>
                                        <Radio value={1}>
                                            {t('more_form.interested_own_body.yes')}
                                        </Radio>
                                        <Radio value={-1}>
                                            {t('more_form.interested_own_body.no')}
                                        </Radio>
                                    </Space>
                                </Radio.Group>
                            </Form.Item>
                        </div>

                        <Button type='primary' htmlType='submit' style={{ width: '100%' }}>
                            {t('more_form.submit')}
                        </Button>
                    </Form>
                </>
            )}
        </div>
    )
}
